import {
  customAlertApiRef,
  GenericEntityType,
  getLabelAndValue,
  KindEnum,
  NotFoundException,
  PreviewMessage,
  selectTemplateEntity,
  TemplateColumn,
  TextFieldValue,
  WbGeneralPurposeEntityPicker,
} from '@agilelab/plugin-wb-platform';
import { witboostSearchApiRef } from '@agilelab/plugin-wb-search';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { FormControl } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { WmFieldExtensionComponentProps } from '../../../extensions/types';
import { isHidden } from '../../utils';
import {
  AvailableEntities,
  entityHandler,
} from './providers/entityProvider.factory';
import { useLazyQuery } from '@apollo/client';
import { GET_ENVIRONMENTS } from './queries';

function isRecord(value: any): value is Record<string, any> {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}

export interface TemplateEntity {
  type: string;
  displayName: string;
  userFilters?: string[];
  columns?: TemplateColumn[];
  filter?: Record<string, any>;
}

export interface EntitySearchPickerUiOptions {
  multiSelection?: boolean;
  entities?: TemplateEntity[];
  allowArbitraryValues?: boolean;
  'ui:widget'?: string;
}

type FormDataType = string | GenericEntityType | (string | GenericEntityType)[];

export const EntitySearchPicker = (
  props: WmFieldExtensionComponentProps<
    FormDataType,
    EntitySearchPickerUiOptions
  >,
) => {
  const {
    formData,
    onChange,
    schema: { title = 'Entity' },
    required,
    uiSchema,
    rawErrors,
    idSchema,
    formContext,
  } = props;

  const catalogApi = useApi(catalogApiRef);
  const searchApi = useApi(witboostSearchApiRef);
  const alertApi = useApi(customAlertApiRef);

  const [templateError, setTemplateError] = useState<PreviewMessage>();
  const [currentValue, setCurrentValue] = useState<TextFieldValue[]>([]);
  const [resolvedValues, setResolvedValues] = useState<
    TextFieldValue[] | undefined
  >(undefined);

  const multiSelection = uiSchema['ui:options']?.multiSelection ?? false;
  const availableKinds = useMemo(() => {
    const kinds = uiSchema['ui:options']?.entities ?? [];
    return kinds.filter(k =>
      Object.values(AvailableEntities).includes(k.type as AvailableEntities),
    );
  }, [uiSchema]);

  const [fetchEnvironments] = useLazyQuery<{
    marketplace_environments: { name: string; priority: number }[];
  }>(GET_ENVIRONMENTS);

  const providerMapper = useMemo(
    () =>
      entityHandler(searchApi, catalogApi, availableKinds, fetchEnvironments),
    [availableKinds, catalogApi, fetchEnvironments, searchApi],
  );

  useEffect(() => {
    if (!availableKinds || availableKinds.length === 0)
      setTemplateError({
        title: 'Error',
        type: 'error',
        content:
          'Error while fetching entities from the template. Please contact the platform team.',
      });
  }, [availableKinds]);

  const fetchData = useCallback(
    async (ref: string) => {
      for (const [key, provider] of Array.from(providerMapper.entries())) {
        if (availableKinds.some(a => a.type === key)) {
          try {
            const res = await provider.resolve(ref);

            if (res) {
              return res;
            }
          } catch (err) {
            if (err instanceof NotFoundException) {
              return undefined;
            }

            alertApi.post({
              error: err,
              severity: 'error',
            });
            return undefined;
          }
        }
      }

      return undefined;
    },
    [alertApi, availableKinds, providerMapper],
  );

  const resolveEntitiesByFormData = useCallback(
    async (data: FormDataType): Promise<TextFieldValue[]> => {
      const checkCurrentValue = (value: string) => {
        const justResolved = currentValue?.find(cv => cv.value === value);
        return justResolved ? justResolved : null;
      };

      const recursiveResolve = async (
        input: FormDataType,
      ): Promise<TextFieldValue[]> => {
        if (Array.isArray(input)) {
          // If input is an array, resolve each element recursively
          const results = await Promise.allSettled(
            input.map(item => recursiveResolve(item)),
          );

          return results
            .filter(result => result.status === 'fulfilled')
            .flatMap(result => (result as PromiseFulfilledResult<any[]>).value);
        }

        if (isRecord(input)) {
          const justResolved = checkCurrentValue(input.value);
          return [
            justResolved ||
              getLabelAndValue(
                input,
                selectTemplateEntity(availableKinds, input.__metadata.kind),
              ),
          ];
        }

        if (typeof input === 'string') {
          const justResolved = checkCurrentValue(input);
          if (justResolved) {
            return [justResolved];
          }

          const entity = await fetchData(input);
          return entity
            ? [entity]
            : [
                {
                  kind: KindEnum.arbitrary,
                  label: input,
                  value: input,
                },
              ];
        }

        return [
          {
            kind: KindEnum.arbitrary,
            label: input,
            value: input,
          },
        ];
      };
      return await recursiveResolve(data);
    },
    [availableKinds, currentValue, fetchData],
  );

  useEffect(() => {
    if (rawErrors?.length) {
      return;
    }
    const resolveEntities = async () => {
      // TODO: refactor this check
      const formDataAvailable =
        props.schema.type === 'object'
          ? Object.keys(formData).length
          : formData;
      if (formDataAvailable && !currentValue.length) {
        const resolvedEntities = await resolveEntitiesByFormData(formData);
        setResolvedValues(resolvedEntities);
      }
    };

    resolveEntities();
  }, [currentValue, formData, resolveEntitiesByFormData, props, rawErrors]);

  const onSelect = useCallback(
    (values: TextFieldValue[] | undefined) => {
      setCurrentValue(values ?? []);
      const formDataValues = values?.map(v => v?.value);
      onChange(multiSelection ? formDataValues : formDataValues?.[0]);
    },
    [multiSelection, onChange],
  );

  return (
    <FormControl
      style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
      required={required}
      error={rawErrors?.length > 0 && !currentValue}
    >
      <WbGeneralPurposeEntityPicker
        title={title}
        required={required}
        id={idSchema?.$id}
        resolvedValues={resolvedValues}
        value={currentValue ?? []}
        onSelect={onSelect}
        freeSolo={
          (uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? false
        }
        multiSelection={multiSelection}
        availableKinds={availableKinds}
        providerMapper={providerMapper}
        parentTemplateRef={formContext.templateRef}
        templateError={templateError}
      />
    </FormControl>
  );
};
